import React from 'react';

import styles from './Heading.module.scss';

export enum HeadingVariant {
  inverted = 'inverted',
  default = 'default',
}

interface HeadingProps {
  level: number;
  variant?: HeadingVariant;
}

export const Heading: React.FC<HeadingProps> = ({
  level,
  variant = HeadingVariant.default,
  children,
}) => {
  // https://stackoverflow.com/a/59685929
  const H = `h${level}` as keyof JSX.IntrinsicElements;

  return <H className={[styles.heading, styles[variant]].join(' ')}>{children}</H>;
};
