import React from 'react';

import { Layout } from 'components/Layout';
import { Heading, HeadingVariant } from 'components/Heading';
import { Gutter } from 'components/Gutter';
import { Faq, Topic } from 'components/sections/Faq';

import styles from './Faq.module.scss';

interface Props {
  faq: {
    title: string;
    questions: Topic[];
  };
}

export const FaqPage: React.FC<Props> = ({ faq }) => (
  <Layout>
    <div className={styles.wrapper}>
      <Heading level={1} variant={HeadingVariant.inverted}>
        Vanliga frågor och svar
      </Heading>
      <Gutter size="small">
        <Faq title="" questions={faq.questions} />
      </Gutter>
    </div>
  </Layout>
);
