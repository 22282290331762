import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';

import { FaqPage as FaqView } from 'components/views/Faq';
import { faq } from 'content/faq';

const faqPage: NextPage = () => (
  <>
    <Head>
      <title>Vanliga frågor och svar - Blipp</title>
      <meta property="og:title" content="Vanliga frågor och svar - Blipp" />
      <meta property="og:description" content="Frågor och svar" />
      <meta name="description" content="Frågor och svar" />
    </Head>

    <FaqView faq={faq} />
  </>
);

export default faqPage;
